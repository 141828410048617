<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <!-- Dialog Header -->
    <slot name="dialogHeader">
      <!-- Provided default dialog header -->
      <base-dialog-header>
        <!-- Dialog Title -->
        <slot name="title">Dialog Title</slot>

        <!-- Dialog Icon -->
        <template v-slot:icon-name v-show="hasSlot('iconName')">
          <slot name="iconName"></slot>
        </template>

        <!--  Dialog Cancel -->
        <template v-slot:icon-cross v-show="hasSlot('iconCrossName')">
          <slot name="iconCrossName"></slot>
        </template>
      </base-dialog-header>
    </slot>

    <!-- Dialog header divider -->
    <v-divider></v-divider>

    <!-- Dialog subtitle -->
    <v-card-subtitle v-show="hasSlot('subtitle')">
      <slot name="subtitle"></slot>
    </v-card-subtitle>

    <!-- Dialog Context -->
    <v-card-text>
      <slot name="default"></slot>
    </v-card-text>

    <!-- Dialog actions divider -->
    <v-divider></v-divider>

    <!-- Dialog Actions -->
    <v-card-actions>
      <slot name="actionsLeft"></slot>
      <!-- push Dialog Action Buttons to the right side -->
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "LayoutDialog",
  inheritAttrs: false,
  mixins: [menuItemSettingsMixin, slottableMixin],
  components: {
    BaseDialogHeader: () => import("@/components/shared/base/BaseDialogHeader")
  }
};
</script>
